<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Ordenes por #Toma</h3>
    </div>
    <div class="container has-background-white is-gapless" style="max-width: initial">
      <div class="columns is-multiline is-gapless">
        <div class="column is-12 has-background-white">
          <div class="p-3">
            <olpaso-indicator/>
          </div>
          <div class="p-3">
            <ForeignWorkSearch @select="handleSelectForeignWork"/>
          </div>
        </div>

        <div class="column is-3 m-3 has-background-white" v-if="foreignWork">
          <b-field label="Fecha inicial">
            <b-datepicker
                v-model="startDate"
                placeholder="Seleccionar fecha inicial">
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-3 m-3  has-background-white" v-if="foreignWork">
          <b-field label="Fecha final">
            <b-datepicker
                v-model="endDate"
                placeholder="Seleccionar fecha final">
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-3 m-3  has-background-white" v-if="foreignWork">
          <b-field label="">
            <b-button
                :disabled="!(startDate && endDate)"
                @click="handleSearch">Filtrar</b-button>
          </b-field>
        </div>

        <div class="column is-12 has-background-white">
          <div class="pt-3 pl-3 pr-3" v-if="foreignWork">
            <p><strong>Empresa: </strong> {{ foreignWork.company.code }} - {{ foreignWork.company.name }}</p>
          </div>
          <div class="pt-3 pl-3 pr-3" v-if="foreignWork">
            <b-button type="is-primary" @click="handlePrint">Imprimir Etiquetas</b-button>
          </div>

          <div class="table-container mt-3 p-3" v-if="foreignWork">
            <table class="table is-fullwidth">
              <thead>
              <tr>
                <th>
                  <b-checkbox
                      v-model="statusSelect"
                      :indeterminate="indeterminate"
                      @input="handleChecksInput"/>
                </th>
                <th>Orden</th>
                <th>Paciente</th>
                <th>Estado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in orders" :key="index">
                <td>
                  <b-checkbox
                      v-model="selectedOrders[`o_${row.id}`]"
                      @input="handleSelect"
                  />
                </td>
                <td>{{ row.order }}</td>
                <td>{{ row.patient.full_name }}</td>
                <td>{{ row.is_cancel ? 'Activa' : 'Cancelada' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import ForeignWorkSearch from "../../components/foreign-work/ForeignWorkSearch.vue";
import OlpasoIndicator from "../../components/print/OlpasoIndicator.vue";

export default {
  name: 'ViewOrdersByForeignWork',
  components: {ForeignWorkSearch, OlpasoIndicator},
  data() {
    return {
      orders: [],
      selectedOrders: {},
      foreignWork: null,
      statusSelect: true,
      startDate: null,
      endDate: null,
    }
  },
  computed: {
    orderIds() {
      const result = [];
      for (const id in this.selectedOrders) {
        if (this.selectedOrders[id]) {
          result.push(parseInt(id.replace('o_', '')));
        }
      }
      return result;
    },
    indeterminate() {
      const selected = Object.values(this.selectedOrders).filter(v => v).length;
      const total = this.orders.length;
      return selected > 0 && selected < total;
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
  },
  methods: {
    handleSelectForeignWork(value) {
      this.foreignWork = value;
      this.startDate = null;
      this.endDate = null;
      //this.getOrders(value.id)
    },
    getOrders(foreignWorkId) {
      this.$loader.show();
      const params = {
        "foreign-work": foreignWorkId
      }
      if(this.startDate && this.endDate) {

        params.start_date = this.startDate.toISOString().substr(0, 10);
        params.end_date = this.endDate.toISOString().substr(0, 10);
      }

      this.selectedOrders = {};
      this.orders = [];
      this.$http.get('/foreign-work-orders', {params}).then(({data}) => {
        const orders = data.data;
        const selectedOrders = {};
        for (const i of orders) {
          selectedOrders[`o_${i.id}`] = true;
        }
        this.selectedOrders = selectedOrders;
        this.orders = orders;
        this.statusSelect = true;
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handlePrint() {
      //console.log(this.orderIds);
      this.$loader.show();
      this.$http.post('/foreign-work-orders/print', {
        orders: this.orderIds
      }).then(() => {
        this.$buefy.dialog.alert({
          title: 'Impresión realizada',
          message: 'Se enviarón a imprimir las etiquetas con éxito',
          type: 'is-success',
          hasIcon: true,
          icon: 'check',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handleSelectAll(status = true) {
      const selectedOrders = {};
      for (const i of this.orders) {
        selectedOrders[`o_${i.id}`] = status;
      }
      this.selectedOrders = selectedOrders;
    },
    handleChecksInput(value) {
      this.handleSelectAll(value);
    },
    handleSelect() {
      const allSelected = this.orders.every(order => {
        return this.selectedOrders[`o_${order.id}`];
      })
      const allNotSelected = this.orders.every(order => {
        return !this.selectedOrders[`o_${order.id}`];
      })
      if (allSelected) {
        this.statusSelect = true;
      }
      if (allNotSelected) {
        this.statusSelect = false;
      }
    },
    handleSearch(){
      this.getOrders(this.foreignWork.id);
    }
  }
}
</script>


<style scoped>

</style>