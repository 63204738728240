<template>
  <div class="indicator">
    <div :class="`indicator__icon ${status ? 'indicator__icon_online': ''}`"></div>
    {{status ? 'Impresora conectada' : 'Impresora desconectada'}}
  </div>
</template>

<script>
 export default {
   name: 'OlpasoIndicator',
   data() {
     return {
       status: false,
       intervalId: null
     }
   },
   mounted() {
     this.ping();
     this.intervalId = setInterval(() => {
       this.ping();
     }, 10000)
   },
   methods: {
     ping() {
       this.$http.post('/ping/olpaso').then(() => {
          this.status = true;
       }).catch(() => {
          this.status = false;
       })
     }
   },
   beforeDestroy() {
     clearInterval(this.intervalId);
   }
 }
</script>

<style scoped>
  .indicator__icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin-right: 5px;
  }

  .indicator__icon_online {
    background-color: greenyellow;
  }
</style>